export const m = {
  "home_name": "首页",
  "about_name": "关于我们",
  "business_1": "抓住政企需求痛点，解决居民生活环境，紧跟国家步伐， 做实、做透老旧社区民生问题",
  "business_name": "业务",
  "business_name_1": "智能设备",
  "media_name": "媒体",
  "partner_name": "合作伙伴",
  "join_name": "加入我们",
  "home": {
    "nav_1": "简介",
    "nav_1_sub_1": "公司介绍",
    "nav_1_sub_2": "荣誉奖项",
    "nav_2": "业务",
    "nav_2_sub_1": "面向政企",
    "nav_2_sub_2": "面向用户",
    "nav_3": "媒体",
    "nav_3_sub_1": "企业动态",
    "nav_3_sub_2": "行业动态",
    "nav_3_sub_3": "媒体报道",
    "nav_4": "合作伙伴",
    "nav_4_sub_1": "合作伙伴",
    "nav_4_sub_2": "合作伙伴",
    "nav_5": "加入我们",
    "nav_5_sub_1": "联系我们",
    "nav_5_sub_2": "加入我们",
    "pre_1": "艾尔伴家是一家90后留学海归创业企业，",
    "pre_2": "公司以“5G、物联网、云计算、大数据、智能硬件”为核心，",
    "pre_3": "赋能社区加速完成“新基建”发展的一家综合性解决方案平台企业。",
    "solution": "我们理解的智慧城市是一种“可持续发展的城市”，其中融合了智慧城市、生态城市、安全城市等各种理念，旨在建设更人性化、更高效、更友好、更安全、更宜居的可持续发展的城市。",
    "solution_1": "智慧社区是社区管理的一种新理念,是新形势下社会管理创新的一种新模式。",
    "solution_2": "智慧消防解决方案通过对消防隐患的全态监控，实现火灾隐患早发现，早消除。",
    "solution_3": "智慧城管是基于网格化管理，新一代信息技术支撑、社会知识创新环境下的城市管理新模式。",
    "solution_4": "智慧园区数字化解决方案实现楼宇园区的数据融合，一体化管理以及智能应用。",
    "solution_5": "主要包括设备监测、交通态势、统计决策和诱导发布等模块，贯穿了交通数据。",
    "solution_6": "采用“9+X”框架，严格遵循《社会治安综合治理综治中心建设与管理规范。",
    "partners_1": "提升社区的安防、智能化和服务，成为伴家的合作伙伴，将会获得够快够强大的资源支持，帮助伙伴以较低的综合成本快速开拓市场，获得最大收益。",
    "partners_2": "合作方式有AI+智慧社区（智能应用）生态合作、政企ICT项目（系统集成）规模合作，长期运营。“互联网+智慧物业”（物联网平台）可持续战略合作等。",
    "join": "加入合作伙伴计划，提升社区的安防、智能化和服务，成为伴家的合作伙伴，将会获得够快够强大的资源支持，帮助伙伴以较低的综合成本快速开拓市场，获得最大收益。",
    "join_1": "网络覆盖",
    "join_2": "个城市",
    "join_3": "联系我们",
    "join_4": "联系电话"
  },
  "about": {
    "tbs_1": "公司介绍",
    "tbs_2": "荣誉奖项",
    "synopsis": "企业简介",
    "synopsis_1": "艾尔伴家",
    "synopsis_2": "创立于重庆",
    "synopsis_3": "位于国家级重点园区重庆市两江新区数字经济产业园（原互联网产业园）。",
    "synopsis_4": "是国家高新技术企业、留学回国人员创业示范单位和“国创会”优秀引进企业。",
    "synopsis_5": "公司通过“互联网、物联网、云计算、大数据”四大核心技术与人工智能深度融合，打造平安智慧城市建设。",
    "synopsis_6": "通过智能硬件整合大数据分析应用，为城市综合治理提供一体化解决方案。",
    "idea_title_1": "匠心",
    "idea_text_1": "产品的每个细节都考虑周到，用心做出用户体验好的产品",
    "idea_title_2": "创新",
    "idea_text_2": "鼓励各种可以落地的创新，即使是微创新",
    "idea_title_3": "合作",
    "idea_text_3": "开放的心态和各类物业公司，供应商展开合作",
    "idea_title_4": "共赢",
    "idea_text_4": "所有的协议都以共赢未目标，不损害合作方的利益",
    "development": "发展历程",
    "statistics_1": "覆盖行业",
    "statistics_2": "解决方案",
    "statistics_3": "成功案例",
    "statistics_4": "合作伙伴",
    "team": "核心团队",
    "awards": "荣誉奖项"
  },
  "business": {
    "tbs_1": "智能门锁",
    "tbs_2": "智能门禁机"
  }
}
