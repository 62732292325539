import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import './styles/commom.scss'
import './assets/icons/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Mint from 'mint-ui';
import 'mint-ui/lib/style.css'
import animated from 'animate.css'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'zh-CN',    // 语言标识
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    'zh-CN': require('./common/lang/zh'),   // 中文语言包
    'en-US': require('./common/lang/en')    // 英文语言包
  }
})
Vue.use(animated)
import  VueTouch from 'vue-touch'
Vue.use(VueTouch,{name:'v-touch'})
VueTouch.config.swipe = {
  threshold:20  //设置左右滑动的距离
}

Vue.use(Mint);

Vue.config.productionTip = false
Vue.use(ElementUI);

new Vue({
  el: '#app',
  i18n,
  router,
  render: h => h(App)
})

