import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const routes = [
  {
    path: '/',
    title:'首页',
    component: () => import('@/views/home/home')
  },
  {
    path: '/home2',
    title:'首页2',
    component: () => import('@/views/home/home2')
  },
  {
    path: '/details/society',
    title:'社会治理',
    component: () => import('@/views/details/society')
  },
  {
    path: '/details/community',
    title: '智慧社区',
    component: () => import('@/views/details/community')
  },
  {
    path: '/details/fire',
    title: '智慧消防',
    component: () => import('@/views/details/fire')
  },
  {
    path: '/details/city',
    title: '智慧城管',
    component: () => import('@/views/details/city')
  },
  {
    path: '/details/park',
    title: '智慧园区',
    component: () => import('@/views/details/park')
  },
  {
    path: '/details/traffic',
    title: '智慧交通',
    component: () => import('@/views/details/traffic')
  },
  {
    path: '/contactUs',
    title: '加入我们',
    component: () => import('@/views/contactUs/contactUs')
  },
  {
    path: '/partners',
    title: '合作伙伴',
    component: () => import('@/views/partners/partners')
  },
  {
    path: '/media',
    title: '企业行业媒体动态',
    component: () => import('@/views/media/media')
  },
  {
    path: '/returnees',
    title: '海归创业',
    component: () => import('@/views/media/returnees/returnees')
  },
  {
    path: '/rated',
    title: '获评',
    component: () => import('@/views/media/rated/rated')
  },
  {
    path: '/safety',
    title: '平安',
    component: () => import('@/views/media/safety/safety')
  },
  {
    path: '/synopsis',
    title: '企业简介',
    component: () => import('@/views/synopsis/synopsis')
  },
  {
    path: '/awards',
    title: '荣誉奖项',
    component: () => import('@/views/synopsis/awards')
  },
  {
    path: '/faceGovernment',
    title: '面向政企',
    component: () => import('@/views/face/government')
  },
  {
    path: '/faceUser',
    title: '面向用户',
    component: () => import('@/views/face/user')
  },
  {
    path: '/userDetails',
    title: '门锁详情',
    component: () => import('@/views/face/userDetails')
  },
  {
    path: '/doorDetails',
    title: '门禁机详情详情',
    component: () => import('@/views/face/doorDetails')
  }
]
const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes
})
const router = createRouter()
export default router