export const m = {
  "home_name": "HOME",
  "about_name": "ABOUT US",
  "business_1": "We should grasp the pain points of government and enterprise needs, solve the living environment of residents, keep up with the pace of the country, and solve the livelihood problems of old communities",
  "business_name": "BUSINESS",
  "business_name_1": "SMART DEVICE",
  "media_name": "MEDIA",
  "partner_name": "PARTNER",
  "join_name": "JOIN US",
  "home": {
    "nav_1": "PROFILE",
    "nav_1_sub_1": "COMPANY INTRODUCTION",
    "nav_1_sub_2": "HONORARY AWARD",
    "nav_2": "BUSINESS",
    "nav_2_sub_1": "FOR ENTERPRISE",
    "nav_2_sub_2": "USER ORIENTED",
    "nav_3": "MEDIA",
    "nav_3_sub_1": "BUSINESS MOVEMENT",
    "nav_3_sub_2": "INDUSTRY TRENDS",
    "nav_3_sub_3": "MEDIA REPORT",
    "nav_4": "PARTNER",
    "nav_4_sub_1": "PARTNER1",
    "nav_4_sub_2": "PARTNER2",
    "nav_5": "JOIN US",
    "nav_5_sub_1": "CONTACT US",
    "nav_5_sub_2": "JOIN US",
    "pre_1": "AirBridge is a post-90s overseas returnees entrepreneurial enterprise,",
    "pre_2": "The company takes \"5G, Internet of Things, cloud computing, big data, intelligent hardware\" as the core,",
    "pre_3": "A comprehensive solution platform enterprise for enabling community to accelerate the completion of \"new infrastructure\" development.",
    "solution": "Smart city is a kind of \"sustainable city\", which integrates the concepts of smart city, ecological city and safe city, aiming to build a sustainable city that is more humane, efficient, friendly, safe and livable.",
    "solution_1": "智慧社区是社区管理的一种新理念,是新形势下社会管理创新的一种新模式。",
    "solution_2": "智慧消防解决方案通过对消防隐患的全态监控，实现火灾隐患早发现，早消除。",
    "solution_3": "智慧城管是基于网格化管理，新一代信息技术支撑、社会知识创新环境下的城市管理新模式。",
    "solution_4": "智慧园区数字化解决方案实现楼宇园区的数据融合，一体化管理以及智能应用。",
    "solution_5": "主要包括设备监测、交通态势、统计决策和诱导发布等模块，贯穿了交通数据。",
    "solution_6": "采用“9+X”框架，严格遵循《社会治安综合治理综治中心建设与管理规范。",
    "partners_1": "To improve the security, intelligence and service of the community, and become the partner of companion home, we will obtain fast and powerful resource support to help partners quickly develop the market at a lower comprehensive cost and obtain the maximum benefits.",
    "partners_2": "Cooperation modes include AI+ intelligent community (intelligent application) ecological cooperation, government-enterprise ICT project (system integration) scale cooperation and long-term operation.\"Internet + Smart Property\" (Internet of Things platform) sustainable strategic cooperation.",
    "join": "Join the partner program, improve the security, intelligence and service of the community, become the partner of the home, will get enough fast and powerful resource support, help partners to quickly develop the market at a lower comprehensive cost, get the maximum benefits.",
    "join_1": "network coverage",
    "join_2": "cities",
    "join_3": "Contact Us",
    "join_4": "contact number"
  },
  "about": {
    "tbs_1": "company introduction",
    "tbs_2": "honorary award",
    "synopsis": "enterprise profile",
    "synopsis_1": "AirBridge",
    "synopsis_2": "Founded in Chongqing",
    "synopsis_3": "It is located in the digital economy industrial park of Chongqing Liangjiang New Area, a national key park (the former Internet industrial park).",
    "synopsis_4": "It is a national high-tech enterprise, an entrepreneurial demonstration unit for overseas returnees and an excellent introduction enterprise of the \"National Innovation Association\".",
    "synopsis_5": "Through the deep integration of the four core technologies of \"Internet, Internet of Things, cloud computing and big data\" and artificial intelligence, the company creates the construction of Ping An smart city.",
    "synopsis_6": "Through intelligent hardware integration of big data analysis applications, to provide integrated solutions for comprehensive urban governance.",
    "idea_title_1": "INGENUITY",
    "idea_text_1": "Every detail of the product is considered carefully, and the intention is to make the product with good user experience",
    "idea_title_2": "INNOVATE",
    "idea_text_2": "Encourage all kinds of innovation that can be realized, even small ones",
    "idea_title_3": "COLLABORATE",
    "idea_text_3": "Open mind to cooperate with all kinds of property companies and suppliers",
    "idea_title_4": "WIN-WIN",
    "idea_text_4": "All the agreements aim at win-win results and will not harm the interests of the partners",
    "development": "DEVELOPMENT HISTORY",
    "statistics_1": "COVER INDUSTRY",
    "statistics_2": "SOLUTION",
    "statistics_3": "SUCCESSFUL CASE",
    "statistics_4": "COOPERATIVE PARTNER",
    "team": "CORE TEAM",
    "awards": "HONORARY AWARD"
  },
  "business": {
    "tbs_1": "智能门锁",
    "tbs_2": "智能门禁机"
  }
}
